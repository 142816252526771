import React from 'react';
import './TwoColumnPoints.css';
import { BsCheckLg } from "react-icons/bs";
import { Row, Col } from 'react-bootstrap';
import SectionHeader from '../SectionHeader/SectionHeader';

const TwoColumnPoints = ({ points, title, subtitle }) => {
    // Split points into pairs
    const midPoint = Math.ceil(points.length / 2);
    const column1 = points.slice(0, midPoint);
    const column2 = points.slice(midPoint);

    return (
        <div className="points-container">
            <div className='points-container-header'>
                <SectionHeader title={title} subtitle={subtitle} />
            </div>
            {column1.map((point1, index) => (
                <Row key={index} className="points-row justify-content-center">
                    <Col md={6} className="points-column">
                        <div className="points-item">
                            <span ><BsCheckLg className="li-promoter" /></span>
                            <span>{point1}</span>
                        </div>
                    </Col>
                    <Col md={6} className="points-column">
                        {column2[index] && (
                            <div className="points-item">
                                <span ><BsCheckLg className="li-promoter" /></span>
                                <span>{column2[index]}</span>
                            </div>
                        )}
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default TwoColumnPoints;
