import React, { useEffect } from 'react';
import CenterStage from '../Components/CenterStage/CenterStage';
import imgCareerStage from '../assets/Careers/career_stage_1.jpg';
import Promoter from '../Components/Promoter/Promoter';
import { Col, Row } from 'react-bootstrap';
import { MdEmail, MdLocationOn } from 'react-icons/md';
import imgWork from '../assets/Careers/work.png';

function Careers() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    const isMobile = window.innerWidth <= 768;

    return (
        <div>
            <CenterStage title={"A PASSION FOR MACHINERY: YOUR CAREER AT HANSA‑FLEX"} imageSrc={imgCareerStage} paragraph={"At HANSA-FLEX, we share a passion for machines and technology. Are you always looking for new challenges? Are you interested in technology and keen to keep everything running smoothly? Then you've come to the right place! You can look forward to an open feedback culture, strong team spirit and exciting tasks. Whether in the office, in the warehouse, in the workshop or in the service fleets: You'll be doing more than just a job!"} />
            {isMobile ?
                <>
                    <div style={{ backgroundColor: '#30557d', padding: '20px', color: 'white', paddingLeft: '10%' }}>
                        <Row>
                            <Col xs={12} md={6}>
                                <h2>Apply Now !</h2>
                                <p>
                                    <MdLocationOn /> HANSA-FLEX Australia Pty. Ltd., Brisbane<br />
                                    Jijaws St 11/45,
                                    4074 Sumner Park,
                                    Australia
                                </p>
                                <p>
                                    <MdEmail /> Email us at <a href="mailto:aus.admin@hansa-flex.com" style={{ color: 'white' }}>aus.admin@hansa-flex.com</a> now!
                                </p>
                            </Col>
                        </Row>
                    </div>
                </> : <Promoter title={"Contact us now !"} checklistItems={["Forge your path with passion and precision at HANSA-FLEX, where every challenge fuels innovation and every opportunity sparks growth."
                    , "Email us at aus.admin@hansa-flex.com"]} imageUrl={imgWork} />}

        </div>
    );
}

export default Careers;