import React, { useEffect } from 'react';
import SectionHeader from '../Components/SectionHeader/SectionHeader';
import TextCaption from '../Components/TextCaption/TextCaption';

const Disclaimer = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <TextCaption title={" Legal Disclaimer "} paragraph={" Thank you for visiting the HANSA-FLEX Australia website. By accessing and using this website, you accept and agree to comply with the terms and conditions outlined in this legal disclaimer. If you do not agree with any part of these terms and conditions, please do not use our website. "}
                bulletPoints={["1. Accuracy of Information: HANSA-FLEX Australia strives to provide accurate and up-to-date information on this website. However, we do not warrant the accuracy, completeness, or reliability of any information, content, or materials provided herein. Users acknowledge that any reliance on such information is at their own risk. "
                    , "2. Use of Content: The content on this website, including but not limited to text, images, graphics, and other materials, is provided for informational purposes only. It is protected by copyright, trademark, and other intellectual property laws. Users may not modify, reproduce, distribute, transmit, display, or perform any content without prior written consent from HANSA-FLEX Australia. "
                    , "3. Third-Party Links: This website may contain links to third-party websites for convenience and informational purposes. HANSA-FLEX Australia does not endorse or take responsibility for the content, accuracy, or privacy practices of such websites. Users access third-party websites at their own risk. "
                    , "4. Limitation of Liability: HANSA-FLEX Australia, its affiliates, directors, employees, or agents shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use or inability to use this website, even if advised of the possibility of such damages. "
                    , "5. Changes to Terms: HANSA-FLEX Australia reserves the right to modify, amend, or update these terms and conditions at any time without prior notice. Users are responsible for reviewing this legal disclaimer periodically to stay informed of any changes. "
                    , "6. Governing Law: This legal disclaimer shall be governed by and construed in accordance with the laws of Australia. Any disputes arising out of or relating to this disclaimer shall be subject to the exclusive jurisdiction of the courts in Australia. "
                    , "7. Copyright: All content on this website is protected by copyright laws. Unauthorized use of any materials may violate copyright, trademark, and other laws. HANSA-FLEX Australia reserves all rights not expressly granted in and to the website and its content. "
                    , "8. Legal Information provided on this website is not intended as legal advice. Users should consult legal professionals for specific advice related to their individual circumstances."
                    , "9. Virus/Interceptions: HANSA-FLEX Australia makes efforts to ensure that this website is free from viruses or other harmful components. However, we cannot guarantee that the website will be secure or free from bugs or viruses. Users are responsible for implementing sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy their particular requirements for the accuracy of data input and output. "
                    , "If you have any questions or concerns regarding this legal disclaimer, please contact us at bris.sales@hansa-flex.com "
                ]} />

        </div>
    )
}

export default Disclaimer