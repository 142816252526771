import React from 'react';
import { Container, Row, Col, } from 'react-bootstrap';
import { FaPhoneVolume } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { BiLogoFacebookCircle } from "react-icons/bi";
import Imgxing from '../../assets/xingImg.png'
import Imglinkidin from '../../assets/Linkdin.png'
import Imgyoutube from '../../assets/youtube.png'
import Imgkaggle from '../../assets/kaggleImg.png'
import Imgfacebook from '../../assets/Facebook.png'
import { Link } from 'react-router-dom';
import './HfFooter.css'

const HfFooter = () => {
  return (
    <footer className='hfFooter-wrapper' id='contact-us-section'>
      <Container className='hfFooter-container'>
        <Row className="justify-content-center " style={{ marginLeft: 'auto' }}>
          <Col sm={12} lg={4} >
            <div className='footer-column-div'>
              <b>How to reach us</b>
              <ul>
                <li><a href="tel:+61 7 3026 5600" className="footer-link"><FaPhoneVolume /> +61 7 3026 5600</a></li>
                <li><a href="mailto:aus.admin@hansa-flex.com" className="footer-link"><MdOutlineEmail /> aus.admin@hansa-flex.com</a></li>
              </ul>
            </div>
          </Col>

          {/* <Col sm={12} lg={3} >
            <div className='footer-column-div'>
              <b>24Hr Rapid Onsite Service </b>
              <ul>
                <li><a href="tel:+971 (0) 800-42672353" className="footer-link"><FaPhoneVolume /> +971 (0) 800 HANSAFLEX (800-426723539)</a></li>
              </ul>
            </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>About HANSA‑FLEX</b>
              <ul>
                <li><Link to="/Careers" className="footer-link">Careers</Link></li>
                <li><Link to="/certificates" className="footer-link">Certificates</Link></li>
                <li> <a href="https://www.hansa-flex.de/en/company/" target="_blank" rel="noopener noreferrer" className="footer-link">More About HANSA-FLEX Group</a></li>
                {/* <li><Link to="/careers" className="footer-link">Careers</Link></li> */}
              </ul>
            </div>
          </Col>


          {/* <Col xs={4} lg={3}>
          <div className='footer-column-div'>
          <h>Service</h>
          <ul>
                <li>Mobile rapid hydraulic service</li>
                <li>Fluid management</li>
                <li>Assembly and installation</li>
                <li>Hydarulic cylinder repair</li>
                <li>Preventive maintenance</li>
            </ul>
          </div>
          </Col> */}

          <Col sm={12} lg={4}>
            <div className='footer-column-div'>
              <b>Popular Categories</b>
              <ul>
                <li><Link to="/hoses-and-hose-lines" className="footer-link">Products</Link></li>
                <a href="https://my.hansa-flex.com.au/en_GB/login" target="_blank" rel="noopener noreferrer" className="footer-link">X-CODE Manager</a>

              </ul>
            </div>
          </Col>

        </Row>

        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <h>Follow us</h>
          </Col>
        </Row>


        <Row className="custom-3row ">

          {/*<Col className="circle-col"><div><img src={Imgkaggle} alt="" /></div></Col>
          <Col className="circle-col"><div><img src={Imgxing} alt="" /></div></Col> */}
          <Col ><div className="circle-col"><a href="https://www.linkedin.com/company/hansa-flex-australia-pty-ltd/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><div>< img src={Imglinkidin} alt="" /></div></a></div></Col>
          <Col><div className="circle-col"><a href="https://www.youtube.com/@HANSAFLEXAG" target="_blank" rel="noopener noreferrer"><div><img src={Imgyoutube} alt="" /></div></a></div></Col>
          {/* <Col><div className="circle-col"><a href="https://www.facebook.com/profile.php?id=1497477143878345&paipv=0&eav=AfY6z8uAnvCIv4xNM285bBzl_37hnWcsBI2ZVuJFFdYBxhYf3f4IUilHEBdXOy3EAfc&_rdr" target="_blank" rel="noopener noreferrer"><div><img src={Imgfacebook} alt="" /></div></a></div></Col> */}

        </Row>

        <Row className="line-row">
          <Col className="line-col"></Col>
        </Row>

        <div className='HfFooter-link-div'>
          <a className="hf-footer-link" href="">© Copyright All Rights Reserved</a><span>  |  </span>
          <Link to="/hansa-flex-india-privacy-policy" className="footer-link">Privacy Policy</Link><span>  |  </span>
          <Link to="/disclaimer" className="footer-link">Disclaimer</Link>

          {/*<a className="hf-footer-link" href="">GT&C</a><span>  |  </span>
          <a className="hf-footer-link" href="">Purchasing and logistics</a><span>  |  </span>
        <a className="hf-footer-link" href="">Data protection</a>*/}

        </div>



      </Container>
    </footer >

  );
};

export default HfFooter;
